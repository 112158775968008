import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "product-box__segment-header" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "product-box__header-container" }
const _hoisted_4 = { class: "product-box__name" }
const _hoisted_5 = {
  key: 0,
  class: "product-box__bottle-amount"
}
const _hoisted_6 = {
  key: 1,
  class: "product-box__vintage"
}
const _hoisted_7 = {
  key: 0,
  class: "product-box__producer"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 3 }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.productBoxData.personalPreferenceMessageKey && !_ctx.showReview)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.personalizedBannerLink ? 'a' : 'div'), {
          key: 0,
          class: _normalizeClass(["product-box__personalization-banner | personalization__banner", `personalization__banner--type_${_ctx.personalPreferenceRank}`]),
          href: _ctx.personalizedBannerLink
        }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "product-box__personalization-banner-icon | personalization__banner-icon" }, null, -1 /* HOISTED */)),
            _cache[2] || (_cache[2] = _createTextVNode()),
            _createElementVNode("div", {
              class: "product-box__personalization-banner-content | personalization__banner-content",
              innerHTML: _ctx.$t(_ctx.productBoxData.personalPreferenceMessageKey)
            }, null, 8 /* PROPS */, _hoisted_2)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "href"]))
      : _createCommentVNode("v-if", true),
    _cache[9] || (_cache[9] = _createTextVNode()),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productLink ? 'a' : 'div'), {
        class: "product-box__header-text",
        href: _ctx.productLink
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, [
            (_ctx.numberOfBottlesInSet)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.numberOfBottlesInSet) + " ×\n          ", 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _cache[3] || (_cache[3] = _createTextVNode()),
            (_ctx.productBoxData.vintage)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.productBoxData.vintage), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createTextVNode(" " + _toDisplayString(_ctx.productBoxData.description1), 1 /* TEXT */)
          ]),
          _cache[4] || (_cache[4] = _createTextVNode()),
          (_ctx.productBoxData.shortDescription)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.productBoxData.shortDescription), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _cache[5] || (_cache[5] = _createTextVNode()),
          (_ctx.productBoxData.shortDescription2)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "product-box__short-description",
                innerHTML: _ctx.productBoxData.shortDescription2
              }, null, 8 /* PROPS */, _hoisted_8))
            : _createCommentVNode("v-if", true),
          _cache[6] || (_cache[6] = _createTextVNode()),
          (_ctx.productBoxData.description3)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "product-box__short-description",
                innerHTML: _ctx.productBoxData.description3
              }, null, 8 /* PROPS */, _hoisted_9))
            : _createCommentVNode("v-if", true),
          _cache[7] || (_cache[7] = _createTextVNode()),
          (_ctx.showInBasket && _ctx.productBoxData.inBasket)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("a", {
                  class: "product-box__status product-box__status--basket product-box__status--basket-up",
                  href: _ctx.basketUrl
                }, _toDisplayString(_ctx.$t('ads.ads_top_info.article_in_basket')), 9 /* TEXT, PROPS */, _hoisted_11)
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["href"])),
      _cache[8] || (_cache[8] = _createTextVNode()),
      (!_ctx.productBoxData.zeroPriceItem && _ctx.productBoxData.valid && !_ctx.productBoxData.recurringOrderFollowUpPacket)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(["product-box__button-wishlist", {
          'product-box__button-wishlist--filled': _ctx.isWishlistEnabled && _ctx.productBoxData.inWishlist,
          'product-box__button-wishlist--remove': !_ctx.isWishlistEnabled,
          'product-box__button-wishlist--loading': _ctx.state === 'loadingWishlist',
        }]),
            "aria-label": _ctx.$t('wishlist.default.name'),
            type: "button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('wishlistClicked')), ["prevent"]))
          }, null, 10 /* CLASS, PROPS */, _hoisted_12))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}