<template>
  <div class="product-box__segment-header">
    <component
      :is="personalizedBannerLink ? 'a' : 'div'"
      v-if="productBoxData.personalPreferenceMessageKey && !showReview"
      class="product-box__personalization-banner | personalization__banner"
      :class="`personalization__banner--type_${personalPreferenceRank}`"
      :href="personalizedBannerLink"
    >
      <div class="product-box__personalization-banner-icon | personalization__banner-icon" />
      <div
        class="product-box__personalization-banner-content | personalization__banner-content"
        v-html="$t(productBoxData.personalPreferenceMessageKey)"
      />
    </component>
    <div class="product-box__header-container">
      <component
        class="product-box__header-text"
        :is="productLink ? 'a' : 'div'"
        :href="productLink"
      >
        <span class="product-box__name">
          <span
            class="product-box__bottle-amount"
            v-if="numberOfBottlesInSet"
          >
            {{ numberOfBottlesInSet }} &times;
          </span>
          <span
            v-if="productBoxData.vintage"
            class="product-box__vintage"
          >
            {{ productBoxData.vintage }}
          </span>
          {{ productBoxData.description1 }}
        </span>
        <span
          class="product-box__producer"
          v-if="productBoxData.shortDescription"
        >
          {{ productBoxData.shortDescription }}
        </span>
        <span
          class="product-box__short-description"
          v-if="productBoxData.shortDescription2"
          v-html="productBoxData.shortDescription2"
        />
        <div
          class="product-box__short-description"
          v-if="productBoxData.description3"
          v-html="productBoxData.description3"
        />
        <div
          v-if="showInBasket && productBoxData.inBasket"
        >
          <a
            class="product-box__status product-box__status--basket product-box__status--basket-up"
            :href="basketUrl"
          >
            {{ $t('ads.ads_top_info.article_in_basket') }}
          </a>
        </div>
      </component>

      <button
        v-if="!productBoxData.zeroPriceItem && productBoxData.valid && !productBoxData.recurringOrderFollowUpPacket"
        class="product-box__button-wishlist"
        :class="{
          'product-box__button-wishlist--filled': isWishlistEnabled && productBoxData.inWishlist,
          'product-box__button-wishlist--remove': !isWishlistEnabled,
          'product-box__button-wishlist--loading': state === 'loadingWishlist',
        }"
        :aria-label="$t('wishlist.default.name')"
        type="button"
        @click.prevent="$emit('wishlistClicked')"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import {defineComponent} from 'vue';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import jsGlobalConfig from '../../../bps/globals/jsGlobalConfig';

  export default defineComponent({
    name: 'ProductBoxSegmentHeader',

    props: {
      productBoxData: {
        type: Object as () => ProductBoxData,
        required: true,
      },
      state: {
        type: String,
        required: true,
      },
      numberOfBottlesInSet: {
        type: Number,
        default: 0,
      },
      productLink: {
        type: String,
        default: '',
      },
      showReview: {
        type: Boolean,
        default: false,
      },
      showInBasket: {
        type: Boolean,
        default: false,
      },
      isWishlistEnabled: {
        type: Boolean,
        default: true,
      },
    },

    setup() {
      return {
        basketUrl: jsGlobalConfig().getBasketUrl(),
      };
    },

    computed: {
      personalizedBannerLink() {
        return this.$t(`productbox.link.${this.productBoxData.personalPreferenceMessageKey}`, [this.productBoxData.url]);
      },
      personalPreferenceRank() {
        return this.productBoxData.personalPreferenceMessageKey.split('.').pop();
      },
    }
  });
</script>
